.smartbanner {
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
  width: 100%;
  height: 84px;
  background: #f3f3f3;
  font-family: 'SF Pro Display', Montserrat, Helvetica, sans, sans-serif;
  /** Android styles **/
}
.smartbanner__exit {
  position: absolute;
  top: calc(50% - 6px);
  left: 9px;
  display: block;
  margin: 0;
  width: 12px;
  height: 12px;
  border: 0;
  text-align: center;
}
.smartbanner__exit::before,
.smartbanner__exit::after {
  position: absolute;
  width: 1px;
  height: 12px;
  background: #767676;
  content: ' ';
}
.smartbanner__exit::before {
  transform: rotate(45deg);
}
.smartbanner__exit::after {
  transform: rotate(-45deg);
}
.smartbanner__icon {
  position: absolute;
  top: 10px;
  left: 30px;
  width: 64px;
  height: 64px;
  border-radius: 15px;
  background-size: 64px 64px;
}
.smartbanner__info {
  position: absolute;
  top: 10px;
  left: 104px;
  display: flex;
  overflow-y: hidden;
  width: 60%;
  height: 64px;
  align-items: center;
  color: #000;
}
.smartbanner__info__title {
  font-size: 14px;
  font-weight: 400;
}
.smartbanner__info__author,
.smartbanner__info__price {
  font-size: 14px;
  font-weight: 400;
}

.smartbanner__info__price {
  margin-top: 12px;
}

.smartbanner__button {
  position: absolute;
  top: 32px;
  right: 10px;
  z-index: 1;
  display: block;
  padding: 0 10px;
  min-width: 10%;
  border-radius: 5px;
  background: #f3f3f3;
  color: #3478f6;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  text-decoration: none;
}
.smartbanner__button__label {
  text-align: center;
}
.smartbanner.smartbanner--android {
  background: rgba(245, 245, 245, 0.6);
}
.smartbanner.smartbanner--android .smartbanner__exit {
  left: 6px;
  margin-right: 7px;
  width: 17px;
  height: 17px;
  border-radius: 14px;
  box-shadow: none;
  color: #000;
  font-family: 'SF Pro Display', Montserrat, 'ArialRoundedMTBold', Arial;
  font-size: 20px;
  font-weight: 400;
  line-height: 17px;
}
.smartbanner.smartbanner--android .smartbanner__exit::before,
.smartbanner.smartbanner--android .smartbanner__exit::after {
  top: 3px;
  left: 8px;
  width: 2px;
  height: 11px;
  background: #b1b1b3;
}
.smartbanner.smartbanner--android .smartbanner__exit:active,
.smartbanner.smartbanner--android .smartbanner__exit:hover {
  color: #eee;
}
.smartbanner.smartbanner--android .smartbanner__icon {
  background-color: transparent;
  box-shadow: none;
}
.smartbanner.smartbanner--android .smartbanner__info {
  color: #848489;
}
.smartbanner.smartbanner--android .smartbanner__info__title {
  color: #000;
}
.smartbanner.smartbanner--android .smartbanner__button {
  top: 30px;
  right: 20px;
  padding: 0;
  min-width: 12%;
  border-radius: 0;
  background: none;
  color: #3478f6;
  font-size: 18px;
  font-weight: 400;
}
.smartbanner.smartbanner--android .smartbanner__button:active,
.smartbanner.smartbanner--android .smartbanner__button:hover {
  background: none;
}
.smartbanner.smartbanner--android .smartbanner__button__label {
  display: block;
  padding: 0 10px;
  background: none;
  box-shadow: none;
  line-height: 24px;
  text-align: center;
  text-shadow: none;
  text-transform: none;
}

/*# sourceMappingURL=smartbanner.css.map */
