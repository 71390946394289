@import './variables';

.xos-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: super;
  height: 16px;
  width: 16px;
}

.icon-eye {
  background-image: url('../assets/images/eye.svg');
  width: 20px;
  height: 12px;
}

.icon-eye-open {
  background-image: url('../assets/images/eye-open.svg');
  width: 20px;
  height: 12px;
}

.icon-notification {
  background-image: url('../assets/images/notification.svg');
}

.icon-settings {
  background-image: url('../assets/images/settings.svg');
}

.icon-settings-gray-outline {
  background-image: url('../assets/images/settings-gray-outline.svg');
}

.icon-settings-primary-outline {
  background-image: url('../assets/images/settings-primary-outline.svg');
}

.icon-education {
  background-image: url('../assets/images/education.svg');
  width: 20.7px;
}

.icon-search {
  background-image: url('../assets/images/search.svg');
}

.icon-search-grey {
  background-image: url('../assets/images/search-grey.svg');
}

.icon-admin {
  background-image: url('../assets/images/admin.svg');
  width: 20px;
  height: 20px;
}

.icon-administration {
  background-image: url('../assets/images/admin.svg');
  width: 20px;
  height: 20px;
}

.icon-admin-black {
  background-image: url('../assets/images/admin-black.svg');
  width: 20px;
  height: 20px;
}

.icon-maintenance {
  background-image: url('../assets/images/maintenance.svg');
  width: 20px;
  height: 20px;
}

.icon-maintenance-black {
  background-image: url('../assets/images/maintenance-black.svg');
  width: 20px;
  height: 20px;
}

.icon-inspections {
  background-image: url('../assets/images/maintenance-black.svg');
  width: 64px;
  height: 64px;
}

.icon-vehicles {
  background-image: url('../assets/images/vehicle.svg');
  width: 20px;
  height: 20px;
}

.icon-vehicles-black {
  background-image: url('../assets/images/vehicle-black.svg');
  width: 20px;
  height: 20px;
}

.icon-vehicle-grey {
  background-image: url('../assets/images/vehicle-grey.svg');
  width: 20px;
  height: 20px;
}

.icon-vehicle-grey-light {
  background-image: url('../assets/images/vehicle-grey-light.svg');
  width: 10px;
  height: 10px;
}

.icon-payment {
  background-image: url('../assets/images/payment.svg');
  width: 18px;
  height: 19px;
}

.icon-insights {
  background-image: url('../assets/images/insights.svg');
  width: 17.35px;
  height: 18.35px;
}

.icon-dashboard {
  background-image: url('../assets/images/dashboard.svg');
  width: 20px;
  height: 20px;
}

.icon-dashboard-black {
  background-image: url('../assets/images/dashboard-black.svg');
  width: 20px;
  height: 20px;
}

.icon-chargers-black {
  background-image: url('../assets/images/chargers-black.svg');
  width: 20px;
  height: 20px;
}

.icon-chargers {
  background-image: url('../assets/images/chargers.svg');
  width: 20px;
  height: 20px;
}

.icon-charger-grey {
  background-image: url('../assets/images/charger-grey.svg');
  width: 16px;
  height: 16px;
}

.icon-telematics {
  background-image: url('../assets/images/telematics.svg');
  width: 18px;
  height: 18px;
}

.icon-knowledgecenter {
  background-image: url('../assets/images/knowledge.svg');
  width: 20px;
  height: 16px;
}

.icon-knowledgecenter-black {
  background-image: url('../assets/images/knowledge-black.svg');
  width: 20px;
  height: 16px;
}

.icon-general {
  background-image: url('../assets/images/general.svg');
  width: 17px;
  height: 17px;
}

.icon-filter {
  background-image: url('../assets/images/filter.svg');
  width: 14.4px;
  height: 13.6px;
}

.icon-home-black {
  background-image: url('../assets/images/home-black.svg');
  width: 18.6px;
  height: 17px;
}

.icon-home {
  background-image: url('../assets/images/home.svg');
  width: 18.6px;
  height: 17px;
}

.icon-attachment {
  background-image: url('../assets/images/attachment.svg');
  width: 18px;
  height: 16px;
}

.icon-comment {
  background-image: url('../assets/images/comment.svg');
  width: 18px;
  height: 17px;
}

.icon-actions {
  background-image: url('../assets/images/actions.svg');
  width: 16px;
  height: 2px;
}

.icon-actions-white {
  background-image: url('../assets/images/actions-white.svg');
  width: 16px;
  height: 2px;
}

.icon-flag {
  background-image: url('../assets/images/flag.svg');
  width: 14.2px;
  height: 18.3px;
}

.icon-close {
  background-image: url('../assets/images/close.svg');
  width: 10.7px;
  height: 10.7px;
}

.icon-close-white {
  background-image: url('../assets/images/close-white.svg');
  width: 10.7px;
  height: 10.7px;
}

.icon-close-grey {
  background-image: url('../assets/images/close-grey.svg');
  width: 10.7px;
  height: 10.7px;
}

.icon-close-circle {
  background-image: url('../assets/images/close-circle.svg');
  width: 16px;
  height: 16px;
}

.icon-arrow-left {
  background-image: url('../assets/images/arrow-left.svg');
  height: 8.7px;
  width: 5.7px;
}

.icon-arrow-right {
  background-image: url('../assets/images/arrow-left.svg');
  transform: rotate(180deg);
  height: 8.7px;
  width: 5.7px;
}

.icon-arrow-left-thin {
  background-image: url('../assets/images/arrow-left-thin.svg');
  width: 7px;
  height: 12.7px;
}

.icon-arrow-white {
  background-image: url('../assets/images/arrow-white.svg');
  width: 8px;
  height: 12px;
}

.icon-arrow-white-small {
  background-image: url('../assets/images/arrow-white-small.svg');
  width: 6px;
  height: 8px;
}

.icon-phone {
  background-image: url('../assets/images/phone.svg');
  height: 18px;
  width: 18px;
}

.icon-mail {
  background-image: url('../assets/images/mail.svg');
  height: 14px;
  width: 20px;
}

.icon-mail-white {
  background-image: url('../assets/images/mail-white.svg');
  height: 40px;
  width: 40px;
}

.icon-info {
  background-image: url('../assets/images/info.svg');
  height: 14.4px;
  width: 14.4px;
  min-width: 14.4px;
}

.icon-info-light-grey {
  background-image: url('../assets/images/info-light-grey.svg');
  height: 12px;
  width: 12px;
}

.icon-clock {
  background-image: url('../assets/images/clock.svg');
  height: 20px;
  width: 20px;
}

.icon-clock-white {
  background-image: url('../assets/images/clock-white.svg');
  height: 16px;
  width: 16px;
}

.icon-success {
  background-image: url('../assets/images/success.svg');
  height: 32px;
  width: 32px;
}

.icon-cancel {
  background-image: url('../assets/images/cancel.svg');
  height: 32px;
  width: 32px;
}

.icon-trash {
  background-image: url('../assets/images/trash.svg');
  height: 32px;
  width: 32px;
}

.icon-time {
  background-image: url('../assets/images/time.svg');
  height: 20px;
  width: 20px;
}

.icon-time-until {
  background-image: url('../assets/images/time-until.svg');
  height: 20px;
  width: 20px;
}

.icon-money {
  background-image: url('../assets/images/money.svg');
  height: 20px;
  width: 20px;
}

.icon-question {
  background-image: url('../assets/images/question.svg');
  height: 16.2px;
  width: 16.2px;
}

.icon-support-chat {
  background-image: url('../assets/images/support-chat.svg');
  height: 20px;
  width: 20px;
}

.icon-pdf {
  background-image: url('../assets/images/pdf-icon.svg');
  width: 24px;
  height: 24px;
}

.icon-jpg {
  background-image: url('../assets/images/jpg-icon.svg');
  width: 24px;
  height: 24px;
}

.icon-download {
  background-image: url('../assets/images/download.svg');
  width: 17.5px;
  height: 18px;
}

.icon-checkmark {
  background-image: url('../assets/images/checkmark.svg');
  height: 8.5px;
  width: 11.2px;
}

.icon-checkmark-grey {
  background-image: url('../assets/images/checkmark-grey.svg');
  height: 9px;
  width: 12px;
}

.icon-checkmark-black {
  background-image: url('../assets/images/checkmark-black.svg');
  height: 8.5px;
  width: 11.2px;
}

.icon-cart {
  background-image: url('../assets/images/cart.svg');
  height: 19.15px;
  width: 20px;
}

.icon-cart-black {
  background-image: url('../assets/images/cart-black.svg');
  height: 19.15px;
  width: 20px;
}

.icon-guide {
  background-image: url('../assets/images/guide.svg');
  height: 90px;
  width: 90px;
  &:hover {
    height: 90px;
    width: 125px;
    background-image: url('../assets/images/guide-active.svg');
    background-position: center;
  }
}

.icon-chat {
  background-image: url('../assets/images/chat.svg');
  height: 90px;
  width: 90px;
  &:hover {
    height: 90px;
    width: 181px;
    background-image: url('../assets/images/chat-active.svg');
  }
}

.icon-vehicle-active {
  background-image: url('../assets/images/markers/vehicle-active.svg');
  height: 32px;
  width: 32px;
}

.icon-vehicle-active-hover {
  background-image: url('../assets/images/markers/vehicle-active-hover.svg');
  height: 32px;
  width: 32px;
}

.icon-vehicle-charging {
  background-image: url('../assets/images/markers/vehicle-charging.svg');
  height: 32px;
  width: 32px;
}

.icon-vehicle-charging-hover {
  background-image: url('../assets/images/markers/vehicle-charging-hover.svg');
  height: 32px;
  width: 32px;
}

.icon-vehicle-in-service {
  background-image: url('../assets/images/markers/vehicle-in-service.svg');
  height: 32px;
  width: 32px;
}

.icon-vehicle-in-service-hover {
  background-image: url('../assets/images/markers/vehicle-in-service-hover.svg');
  height: 32px;
  width: 32px;
}

.icon-vehicle-need-charge {
  background-image: url('../assets/images/markers/vehicle-need-charge.svg');
  height: 32px;
  width: 32px;
}

.icon-vehicle-need-charge-hover {
  background-image: url('../assets/images/markers/vehicle-need-charge-hover.svg');
  height: 32px;
  width: 32px;
}

.icon-vehicle-ready-to-go {
  background-image: url('../assets/images/markers/vehicle-ready-to-go.svg');
  height: 32px;
  width: 32px;
}

.icon-vehicle-ready-to-go-hover {
  background-image: url('../assets/images/markers/vehicle-ready-to-go-hover.svg');
  height: 32px;
  width: 32px;
}

.icon-vehicle-inactive,
.icon-vehicle-unknown {
  background-image: url('../assets/images/markers/vehicle-inactive.svg');
  height: 32px;
  width: 32px;
}

.icon-vehicle-inactive-hover,
.icon-vehicle-unknown-hover {
  background-image: url('../assets/images/markers/vehicle-inactive-hover.svg');
  height: 32px;
  width: 32px;
}

.icon-vehicle-nickname {
  background-image: url('../assets/images/vehicle-nickname.svg');
  height: 20px;
  width: 20px;
}

.icon-vehicle-nickname-white {
  background-image: url('../assets/images/vehicle-nickname-white.svg');
  height: 20px;
  width: 20px;
}

.icon-charger-nickname {
  background-image: url('../assets/images/charger-nickname.svg');
  height: 20px;
  width: 20px;
}

.icon-charger-nickname-white {
  background-image: url('../assets/images/charger-nickname-white.svg');
  height: 20px;
  width: 20px;
}

.icon-stroke {
  background-image: url('../assets/images/stroke.svg');
  height: 18px;
  width: 18px;
}

.icon-stroke-black {
  background-image: url('../assets/images/stroke-black.svg');
  height: 18px;
  width: 18px;
}

.icon-bell-black {
  background-image: url('../assets/images/bell-black.svg');
  height: 12px;
  width: 12px;
}

.icon-bell-reminders-on {
  background-image: url('../assets/images/bell-filled.svg');
  height: 16px;
  width: 16px;
}

.icon-bell-reminders-off {
  background-image: url('../assets/images/bell-grey.svg');
  height: 16px;
  width: 16px;
}

.icon-bell {
  background-image: url('../assets/images/bell.svg');
  height: 32px;
  width: 32px;
}

.icon-leaf {
  background-image: url('../assets/images/leaf.png');
  height: 61px;
  width: 89px;
}

.icon-vehicle-delivery {
  background-image: url('../assets/images/vehicle-delivery.png');
  height: 16px;
  width: 16px;
}

.icon-open {
  mask-image: url('../assets/images/open-arrow.svg');
  -webkit-mask-image: url('../assets/images/open-arrow.svg');
  background-color: $double-grey;

  height: 16px;
  width: 16px;
  &:hover {
    background-color: $primary-bg;
  }
}

.icon-warning {
  background-image: url('../assets/images/warning.svg');
  height: 30px;
  width: 30px;
}

.icon-warning-white {
  background-image: url('../assets/images/warning-white.svg');
  height: 16px;
  width: 16px;
}

.icon-warning-red {
  background-image: url('../assets/images/warning-red.svg');
  height: 12px;
  width: 12px;
}

.icon-warning-yellow {
  background-image: url('../assets/images/warning-yellow.svg');
  height: 30px;
  width: 30px;
}

.icon-charger-issue {
  background-image: url('../assets/images/charger-issue.svg');
  width: 20px;
  height: 20px;
}

.icon-charger-connected {
  background-image: url('../assets/images/charger-connected.svg');
  width: 20px;
  height: 20px;
}

.icon-charger-idle {
  background-image: url('../assets/images/charger-idle.svg');
  width: 20px;
  height: 20px;
}

.icon-sales {
  background-image: url('../assets/images/sales.svg');
  width: 20px;
  height: 20px;
}

.icon-sales-black {
  background-image: url('../assets/images/sales-black.svg');
  width: 20px;
  height: 20px;
}

.icon-load-time {
  background-image: url('../assets/images/load-time.svg');
  width: 12px;
  height: 12px;
}

.expander-arrow-expanded {
  -webkit-mask-image: url('../assets/images/expander-arrow-expanded.svg');
  mask-image: url('../assets/images/expander-arrow-expanded.svg');
  mask-repeat: no-repeat;
  width: 10px;
  height: 10px;
}

.expander-arrow-collapsed {
  -webkit-mask-image: url('../assets/images/expander-arrow-collapsed.svg');
  mask-image: url('../assets/images/expander-arrow-collapsed.svg');
  mask-repeat: no-repeat;
  width: 10px;
  height: 10px;
}

.icon-trend-arrow,
.icon-reports {
  background-image: url('../assets/images/trend-arrow.svg');
  height: 18px;
  width: 18px;
}

.icon-powertrain {
  background-image: url('../assets/images/powertrain.svg');
  height: 19px;
  width: 16px;
}

.icon-powertrain-grey {
  background-image: url('../assets/images/powertrain-grey.svg');
  height: 19px;
  width: 16px;
}

.icon-powertrain-nickname {
  background-image: url('../assets/images/powertrain-nickname.svg');
  height: 20px;
  width: 20px;
}

.icon-complete {
  background-image: url('../assets/images/complete.svg');
  height: 20px;
  width: 20px;
}

.icon-complete-grey {
  background-image: url('../assets/images/complete-grey.svg');
  height: 20px;
  width: 20px;
}

.icon-driver {
  background-image: url('../assets/images/driver.svg');
  height: 17px;
  width: 17px;
}

.icon-lock {
  background-image: url('../assets/images/lock.svg');
  height: 32px;
  width: 32px;
}

.icon-lock-colored {
  background-image: url('../assets/images/lock-colored.svg');
  height: 24px;
  width: 24px;
}

.icon-upgrade-stars {
  background-image: url('../assets/images/upgrade-stars.png');
  height: 24px;
  width: 24px;
}

.icon-list-view {
  background-image: url('../assets/images/list-view.svg');
  height: 20px;
  width: 20px;
}

.icon-map-view {
  background-image: url('../assets/images/map-view.svg');
  height: 20px;
  width: 20px;
}

.icon-cards-view {
  background-image: url('../assets/images/cards-view.svg');
  height: 20px;
  width: 20px;
}
