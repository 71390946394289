$primary-bg: #d58547;
$primary-bg-opacity-07: rgba(213, 133, 71, 0.7);
$primary-bg-opacity-03: rgba(213, 133, 71, 0.3);
$primary-disabled: rgba(213, 133, 71, 0.3);
$primary: #070707;
$primary-white: #fff;
$primary-opacity-03: rgba(7, 7, 7, 0.3);
$primary-grey: #f3f2f1;
$primary-grey-opacity: rgba(243, 242, 241, 0.8);
$primary-grey-hover: #e3e3e3;
$primary-grey-hover-03: rgba(227, 227, 227, 0.3);
$primary-grey2: #898989;
$primary-grey2-opacity-03: rgba(137, 137, 137, 0.3);
$double-grey: #605e5c;
$double-grey-opacity-05: rgba(96, 93, 92, 0.5);
$light-black: #202020;
$red: #f80707;
$light-red: #fdb5b5;
$medium-red: #d65745;
$light-pink: #f4b9c1;

$external-link-blue: #0030fc;
$empty-state-background: #f5f5f4;
$green: #3c8825;
$light-green: #88c66b;

$request-in-service: #5da8ed;
$request-scheduled: #b5815b;
$request-pending: #898989;
$request-completed: $light-green;
$request-completed-dulled: rgba(136, 198, 107, 0.2);

$request-in-service-opacity-03: rgba(93, 168, 237, 0.3);
$request-scheduled-opacity-03: rgba(181, 129, 91, 0.3);

$priority-high: #f66565;
$priority-medium: #f2ab6b;
$priority-low: #c4c4c4;
$priority-low-opacity: rgba(196, 196, 196, 0.8);

$medium-yellow: #f4be37;
$medium-yellow-opacity-03: rgba(244, 190, 55, 0.3);
$unavailable: #e2a241;
$no-actions: #f7f7f6;
$light-yellow: #fcecc3;

$timeline-past: #c4c4c4;
$timeline-future: $primary-bg;
