@mixin small-mobile-screen {
  @media (max-width: 399px) {
    @content;
  }
}

@mixin mobile-screen {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin tablet-screen {
  @media (min-width: 481px) and (max-width: 768px) {
    @content;
  }
}

@mixin tablet-and-mobile {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin small-screen {
  @media (min-width: 769px) and (max-width: 1024px) {
    @content;
  }
}

@mixin all-small-screens {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin all-small-screens-expanded {
  @media (max-width: 1099px) {
    @content;
  }
}

@mixin small-laptop {
  @media (min-width: 1025px) and (max-width: 1099px) {
    @content;
  }
}

@mixin laptop-screen {
  @media (min-width: 1025px) and (max-width: 1296px) {
    @content;
  }
}

@mixin laptop-bigger-screen {
  @media (min-width: 1100px) and (max-width: 1296px) {
    @content;
  }
}

@mixin not-large-screen {
  @media (max-width: 1296px) {
    @content;
  }
}
