@import './variables';
@import './icons';
@import './mixins';
@import './smartbanner';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-weight: 600;
  font-size: 32px;
  color: $primary;
  margin: 0;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

mat-form-field {
  width: 100%;
  .mat-form-field-subscript-wrapper {
    padding-left: 0 !important;
    color: red;
  }
  label {
    color: $primary;
    font-size: 14px;
    margin-bottom: 7px;
    display: block;
  }
  .mat-form-field-wrapper {
    .mat-form-field-infix {
      padding: 0;
      border-top: 0;
      position: relative;
    }
    .mat-form-field-flex {
      border-radius: 0;
      padding: 0;
      position: relative;
    }
  }
  .mat-select {
    background: $primary-grey;
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 2px;
    border: 1px solid transparent;
  }
  .mat-input-element {
    background: $primary-grey;
    padding: 6px 35px 6px 12px;
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 2px;
    border: 1px solid transparent;
    &:hover {
      background: $primary-grey-hover;
    }
    &:active,
    &:focus {
      border: 1px solid $primary-bg;
      background: #fff;
    }
    &::placeholder {
      color: $double-grey;
      font-size: 14px;
    }
  }
  &.error {
    label {
      color: $red;
    }
    .mat-input-element {
      border: 1px solid $red;
    }
  }
}

xos-login-form {
  .mat-form-field {
    padding-bottom: 24px;
    &:nth-of-type(2) {
      padding-bottom: 0;
    }
  }
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

.width-100 {
  width: 100%;
}

.bordered[color='accent'] {
  border: 1px solid $primary;
}

mat-checkbox {
  color: $double-grey;
}

a {
  color: $double-grey;
  text-decoration: underline;
  font-size: 14px;
}

.xos-checkbox {
  .mat-checkbox-label {
    color: $double-grey;
    font-size: 14px;
  }
  .mat-checkbox-frame {
    border-width: 1px;
  }
  &.mat-checkbox-checked,
  &.mat-checkbox-indeterminate {
    .mat-checkbox-frame {
      background: $primary-bg;
      border-color: $primary-bg;
    }
  }
  &.mat-checkbox-indeterminate {
    .mat-checkbox-mixedmark {
      background-color: #fff;
      margin-top: -3px;
      margin-left: -4px;
    }
  }
}

button.mat-flat-button.mat-primary {
  border-radius: 2px;
  line-height: 32px;
  font-weight: 600;
  &[disabled] {
    background-color: $primary-disabled;
    color: #fff;
  }
}

.type-toggle {
  width: 20px;
  height: 12px;
  position: absolute;
  z-index: 1;
  right: 12px;
  bottom: 10px;
  cursor: pointer;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  margin-top: 1px;
  margin-left: 1px;
  width: 18px;
  height: 18px;
}

.mat-checkbox-checked .mat-checkbox-checkmark {
  width: 14px;
}
.actions-menu + .cdk-overlay-connected-position-bounding-box .mat-menu-panel {
  min-height: auto;
}

.actions-menu + .cdk-overlay-connected-position-bounding-box,
.user-menu + .cdk-overlay-connected-position-bounding-box {
  border-radius: 2px !important;
  width: 176px;
  .mat-menu-content:not(:empty) {
    padding-top: 0;
    padding-bottom: 0;
    button.mat-menu-item {
      height: 32px;
      line-height: 32px;
      color: $primary;
      background: $primary-grey-hover;
    }
  }
}

.user-menu + .cdk-overlay-connected-position-bounding-box {
  top: 38px !important;
  .mat-menu-panel {
    margin-top: 10px;
    min-width: 176px;
    .mat-menu-content:not(:empty) {
      button.mat-menu-item {
        background: $primary;
        color: $primary-grey;

        &:hover {
          background: $light-black;
        }

        &.log-out {
          color: $red;
        }
      }
    }
  }
}

xos-user-icon {
  width: 28px;
  height: 28px;
  display: block;
}

h1.title {
  margin-bottom: 24px;
}

main:not(.home-page) {
  flex: 1;
  margin: 32px 32px 0;
  position: relative;
  @include tablet-and-mobile {
    margin: 32px 16px;
  }
}

.mat-tab-labels .mat-tab-label {
  opacity: 1;
  padding: 0 8px;
  min-width: 50px;
  height: 42px;
  border-bottom: 2px solid transparent;
  label {
    cursor: pointer;
  }
  &:nth-child(1) {
    padding-left: 0;
  }
  &.mat-tab-label-active {
    border-bottom: 2px solid $primary-bg;
    font-weight: 600;
  }
}

.mat-tab-body-wrapper {
  margin-top: 20px;
}

h2 {
  font-weight: 600;
  font-size: 18px;
}

.small-btn {
  background: $primary-grey;
  border-radius: 2px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.page-wrapper {
  display: flex;
  height: calc(100% - 48px);
}

aside {
  width: 228px;
  box-sizing: border-box;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $primary;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  xos-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

form .mat-form-field-subscript-wrapper {
  position: static;
  padding: 0 !important;
  margin-top: 8px;
  overflow: visible;
}

.request-in-service,
.in-service {
  background: $request-in-service;
}

.scheduled {
  background: $request-scheduled;
}

.pending {
  background: $request-pending;
}

.completed {
  background: $request-completed;
}

.overdue {
  background: $priority-high;
}

.due.soon {
  background: $medium-yellow;
}

.on-hold {
  box-shadow: 0 0 0 1px $request-pending inset;
  background: white;
}

.request-service.no.actions {
  background: $priority-low;
}

.xos-select {
  width: 100%;
  &.mat-form-field-disabled {
    .mat-select-arrow {
      background: none;
    }
  }
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  .mat-form-field-subscript-wrapper {
    margin-top: 0;
  }
  .mat-form-field-flex {
    background: $primary-grey;
    padding: 7px 17px 0 12px !important;
    border: 1px solid transparent;
    &:hover {
      background: $primary-grey-hover;
      .mat-select {
        background: $primary-grey-hover;
      }
    }
  }
  &.mat-focused {
    .mat-form-field-flex {
      background: #fff;
      border: 1px solid $primary-bg;
      .mat-select {
        background: #fff;
      }
    }
  }
  .mat-form-field-infix {
    padding-bottom: 0;
    border-top: 0;
    color: $double-grey;
  }
  .mat-select-arrow {
    width: 8.7px;
    height: 5px;
    border: none;
    background: url('../assets/images/arrow-down.svg') no-repeat center;
    background-origin: border-box;
    margin: 11px 0 0;
  }
}

.xos-select-list.mat-select-panel {
  border: 1px solid $primary-bg;
  border-radius: 2px;
  background: #fff;
  .mat-option {
    color: $primary;
    line-height: 30px;
    height: 30px;
    &:hover {
      background: $primary-grey-hover;
    }
  }
  .mat-option.mat-active {
    font-weight: 500;
    color: $primary;
    background-image: url('../assets/images/checkmark-grey.svg');
    background-repeat: no-repeat;
    background-position: 96% center;
  }
}

.xos-select-panel:not(.mat-select-empty) {
  & + .mat-form-field-label-wrapper label {
    display: none;
  }
  .mat-select-value-text {
    position: relative;
    top: -3px;
    color: $primary;
  }
}

.xos-field-label {
  margin-bottom: 8px;
}

.xos-section-label {
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 24px;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(0) scale(1) !important; /* originally scale(0.75) */
}

.xos-field-wrapper {
  margin-bottom: 12px;
}

.xos-datepicker {
  width: 100%;
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  .mat-form-field-label-wrapper {
    .mat-form-field-label {
      top: 20px;
      left: 12px;
      margin-top: 0;
    }
  }
  .mat-input-element {
    padding-top: 5px;
    padding-bottom: 4px;
    margin-top: 0;
  }
  .mat-form-field-subscript-wrapper {
    margin-top: 0;
  }
  .mat-form-field-flex {
    background: $primary-grey;
    position: relative;
    border: 1px solid transparent;
    &:hover {
      background: $primary-grey-hover;
    }
  }
  &.open .mat-form-field-flex {
    background: #fff;
    border: 1px solid $primary-bg;
    .mat-input-element {
      background: #fff;
    }
  }
  &.mat-focused {
    .mat-form-field-flex {
      background: #fff;
      border: 1px solid $primary-bg;
    }
  }
  mat-datepicker-toggle {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    cursor: pointer;
    button {
      background: url('../assets/images/calendar.svg') no-repeat center;
      .mat-button-wrapper {
        display: none;
      }
    }
  }
  .mat-form-field-infix {
    padding-bottom: 0;
    border-top: 0;
    color: $double-grey;
  }
}

.xos-input {
  .mat-form-field-subscript-wrapper {
    display: none;
  }
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  input.mat-input-element {
    margin-top: 0;
    height: 32px;
  }
  &.dollar-input,
  &.mi-suffix {
    .mat-form-field-prefix,
    .mat-form-field-suffix {
      position: absolute;
      z-index: 2;
      top: 6px;
    }
    .mat-form-field-prefix {
      left: 3px;
    }
    .mat-form-field-suffix {
      right: 10px;
    }
  }
}

mat-calendar {
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.12);
  .mat-calendar-content {
    padding: 0 16px 16px 16px;
  }
  xos-datepicker-header,
  .mat-calendar-header {
    padding: 16px 16px 0 16px;
  }
}

mat-slide-toggle {
  &.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background: $primary-bg;
  }
  &.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(24px, 0, 0);
  }
  .mat-slide-toggle-bar {
    background: $primary-grey2;
    border-radius: 100px;
    width: 48px;
    height: 24px;
  }

  .mat-slide-toggle-thumb {
    background: #fff;
    width: 20px;
    height: 20px;
  }
  .mat-slide-toggle-thumb-container {
    top: 2px;
    left: 2px;
  }
  .mat-slide-toggle-content {
    display: flex;
    justify-content: center;
    white-space: break-spaces;
    i {
      margin-left: 15px;
    }
  }
}

/* this makes it so that sort header arrow is always shown: */
.mat-sort-header-arrow.ng-trigger.ng-trigger-arrowPosition {
  opacity: 1 !important;
}

/* Hide the default sort arrow display: */
.mat-sort-header-stem {
  display: none !important;
}
.mat-sort-header-pointer-left,
.mat-sort-header-pointer-right {
  display: none !important;
}
.mat-sort-header-pointer-middle {
  width: 0 !important;
  height: 0 !important;
}

.mat-sort-header-arrow {
  transform: none !important;
  .mat-sort-header-indicator {
    transform: none !important;
    &::before {
      content: '';
      width: 6px;
      height: 8px;
      position: absolute;
      left: 2px;
      top: 2px;
    }
  }
}

.mat-sort-header-container {
  flex-direction: row-reverse;
  justify-content: flex-end;
  position: relative;
  &:hover {
    color: $primary;
  }
}

.mat-sort-header[aria-sort='none'] .mat-sort-header-container .mat-sort-header-arrow .mat-sort-header-indicator:before {
  background-image: url('../assets/images/sort-arrows.svg');
}
.mat-sort-header[aria-sort='descending'] .mat-sort-header-container .mat-sort-header-arrow .mat-sort-header-indicator:before {
  background-image: url('../assets/images/sort-arrows-descending.svg');
}
.mat-sort-header[aria-sort='ascending'] .mat-sort-header-container .mat-sort-header-arrow .mat-sort-header-indicator:before {
  background-image: url('../assets/images/sort-arrows-ascending.svg');
}

.mat-paginator-outer-container {
  .mat-paginator-page-size-label {
    display: none;
  }
  .mat-paginator-page-size-select {
    width: 88px;
    .mat-form-field-infix {
      padding: 0 16px;
      border: 0;
    }
  }
  .mat-paginator-page-size {
    margin-top: 13px;
  }
}

.paginator-wrapper .mat-select-panel {
  background: $primary-grey;
}

.toast-bottom-left {
  bottom: 92px;
  left: 32px;
}

.toast-container .ngx-toastr {
  width: 228px;
  padding: 18px;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12);
  border-right: 2px;
  &:hover {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.12);
    cursor: default;
  }
}

.hidden {
  display: none !important;
}

button.toast-close-button {
  width: 11px;
  height: 14px;
  font-size: 0;
  &:hover {
    opacity: 1;
  }
  span {
    background: url('../assets/images/close-white.svg') no-repeat center;
    width: 11px;
    height: 11px;
    display: block;
  }
}

mat-tooltip-component {
  .xos-tooltip {
    margin: 3px 14px;
  }
  .mat-tooltip {
    padding: 6px 12px;
    background: $primary;
    color: #fff;
    font-size: 14px;
    position: relative;
    overflow: visible;

    &:after {
      position: absolute;
      content: '';
      bottom: -4px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 4px 0 4px;
      border-color: $primary transparent transparent transparent;
    }
    &.load-time-tooltip-right {
      left: 16px;
      &:after {
        left: 86%;
      }
    }
  }
}

.time-select {
  .mat-select-arrow-wrapper .mat-select-arrow {
    width: 20px;
    height: 20px;
    background-image: url('../assets/images/clock.svg');
    position: relative;
    margin: 0;
    top: 5px;
    right: -10px;
  }
  &.selected {
    .mat-form-field-suffix {
      position: static;
      .mat-icon-button {
        height: 32px !important;
        position: absolute;
        right: 0;
        top: -1px;
      }
    }
    .mat-select-arrow-wrapper .mat-select-arrow {
      background: none;
    }
  }
}

mat-dialog-container {
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12);
}

.request-service {
  width: 16px;
  height: 16px;
  border-radius: 2px;
}

.filters-skeleton {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  span {
    &:first-child {
      margin-left: 0 !important;
    }
    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.skeleton-form-field-wrapper {
  display: flex;
  flex-direction: column;
}

.mat-form-field-disabled {
  input,
  textarea {
    pointer-events: none;
  }
  &:hover {
    input,
    textarea {
      background: $primary-grey-hover;
    }
  }
}

xos-products {
  main {
    height: 100%;
  }
}

.xos-accordion {
  mat-expansion-panel {
    margin: 0;
    &:first-child {
      margin-bottom: 8px;
    }
    background: url('../assets/images/plus.svg') no-repeat right 28px;
    &.mat-expanded {
      background: url('../assets/images/minus.svg') no-repeat right 35px;
      mat-panel-description {
        display: none;
      }
    }
  }
  mat-expansion-panel-header {
    font-weight: 600;
    font-size: 18px;
    padding: 24px 12px 12px 0;
    border-bottom: 0.5px solid $priority-low;
    .mat-content {
      flex-direction: column;
    }
  }
  mat-panel-description {
    font-size: 14px;
    margin-top: 4px;
    font-weight: 400;
    color: $double-grey;
  }
  .mat-expansion-panel-body {
    padding: 20px 0 0 0;
  }
}

.xos-toggle-with-explanation {
  width: 268px;
  padding: 12px;
  border: 1px solid $primary-bg;
  box-sizing: border-box;
  .mat-slide-toggle + .explanation-text + .price {
    margin-left: 0;
  }
  mat-slide-toggle {
    width: 100%;
    .mat-slide-toggle-label {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    .mat-slide-toggle-content {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .explanation-text {
    color: $double-grey;
    margin-top: 8px;
    margin-bottom: 0;
  }
}
mat-form-field textarea.mat-input-element {
  resize: none;
  color: $double-grey;
  height: 76px;
  padding: 6px 12px;
  box-sizing: border-box;
  &::placeholder {
    color: $double-grey;
  }
  &:hover {
    background: $primary-grey-hover;
  }
  &:focus:hover {
    background: #fff;
  }
}

.xos-plus-minus-input {
  display: flex;
  span {
    cursor: pointer;
    &:hover {
      background: $primary-grey-hover;
    }
  }
  .minus,
  .plus {
    width: 32px;
    height: 32px;
    background: $primary-grey;
    color: $double-grey;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }

  mat-form-field {
    width: 32px;
    margin: 0 2px;
  }

  input {
    width: 32px;
    height: 32px;
    padding: 0;
    border: none;
    font-size: 14px;
    text-align: center;
    &[type='number'] {
      -moz-appearance: textfield;
    }
    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

#pendo-guide-container button {
  cursor: pointer;
  font-family: 'Montserrat', sans-serif !important;
  &._pendo-close-guide {
    font-weight: 300 !important;
  }
}

#pendo-g-8wkHznVBm6n6WQU6ycnvyLvZDEU {
  top: 15px;
}

#pendo-g-sa9zDykDKKakbOggpoGeCfOWlA8 {
  top: -300px;
  left: -20px;
}

#pendo-g-0ld3H-QsposSssC9MTyo-zpIvjo,
#pendo-g-fJ1CkvN8EyHGo_tiz0QMLNX2Jec,
#pendo-g-6mJwHMBNeQO9ml3SwOTZFZaUZjo {
  left: -44px;
}

#pendo-g-HKR8hGVg7ZoxEUIL2vdGGfluCCY {
  top: 20px;
}

#pendo-g-TKRG42ScQFrXWr8j_OkGsIs1S-M {
  top: 20px;
  left: 17px;
}

#pendo-radio-group-wrapper-28ff5eb0 {
  label {
    border-radius: 50% !important;
    border: 1px solid #fff;
    font-family: 'Montserrat', sans-serif !important;
  }
  div {
    margin-top: 12px;
    font-size: 8px !important;
    font-weight: 400;
  }
  input[type='radio'].pendo-radio:checked + label {
    background-color: #fff !important;
    color: $primary !important;
  }
}

.close-sidenav {
  position: absolute;
  top: 33px;
  left: -39px;
  z-index: 9;
  width: 10.7px;
  height: 10.7px;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  @include tablet-and-mobile {
    left: unset;
    right: 26px;
    top: 29px;
    &.hide-close {
      display: none;
    }
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }
}

#pendo-base {
  z-index: 1 !important;
}

.xos-autocomplete {
  ::ng-deep {
    .mat-form-field-flex {
      padding: 0;
    }
  }
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

.mat-autocomplete-panel {
  background-color: #fff;
  margin-top: -1px;
  .mat-option {
    color: $primary !important;
    font-size: 14px;
    line-height: 30px;
    height: 30px;
  }
  border: 1px solid $primary-bg;
  border-top: none;
}

.vehicle-marker {
  border-radius: 50%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  background-repeat: no-repeat;
  background-position: center;
  width: 32px;
  height: 32px;
  &.selected {
    border: 12px solid $primary-opacity-03;
    z-index: 5;
  }
  &.hovered {
    z-index: 5;
  }
}

.charge-status {
  position: absolute;
  top: -15px;
  line-height: 14px;
  left: 0;
  right: 0;
  color: #fff;
  border: 0.5px solid #fff;
  border-radius: 2px;
  width: 32px;
  height: 14px;
  text-align: center;
  font-size: 9px;
  box-sizing: border-box;
  font-style: normal;
}

.icon-vehicle-charging .charge-status,
.icon-vehicle-ready-to-go .charge-status {
  background: $request-completed;
}

.icon-vehicle-need-charge .charge-status {
  background: $priority-high;
}

.icon-vehicle-active .charge-status {
  background: $primary;
}

.icon-vehicle-in-service .charge-status {
  background: $request-in-service;
}

.icon-vehicle-inactive .charge-status {
  background: $request-pending;
}

.icon-vehicle-unknown .charge-status {
  background: $request-pending;
}

.icon-vehicle-ready-to-go:hover {
  background: url('../assets/images/markers/vehicle-ready-to-go-hover.svg') no-repeat;
}

.icon-vehicle-charging:hover {
  background: url('../assets/images/markers/vehicle-charging-hover.svg') no-repeat;
}

.icon-vehicle-need-charge:hover {
  background: url('../assets/images/markers/vehicle-need-charge-hover.svg') no-repeat;
}

.icon-vehicle-active:hover {
  background: url('../assets/images/markers/vehicle-active-hover.svg') no-repeat;
}

.icon-vehicle-in-service:hover {
  background: url('../assets/images/markers/vehicle-in-service-hover.svg') no-repeat;
}

.icon-vehicle-inactive:hover {
  background: url('../assets/images/markers/vehicle-inactive-hover.svg') no-repeat;
}

.icon-vehicle-unknown:hover {
  background: url('../assets/images/markers/vehicle-inactive-hover.svg') no-repeat;
}

.mapboxgl-ctrl-bottom-left {
  display: flex;
  flex-direction: row-reverse;
  .mapboxgl-ctrl-logo {
    background-image: url('../assets/images/mapbox-logo.svg');
    width: 100px;
  }
  .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    background-color: transparent;
    font-size: 14px;
    a {
      color: $primary;
    }
    .mapbox-improve-map {
      font-weight: 400;
    }
  }
}

.empty-state {
  text-align: center;
  padding-top: 80px;
  .text {
    max-width: 350px;
    margin: 0 auto;
  }
  a {
    color: $primary-bg;
    text-decoration: none;
    cursor: pointer;
  }
  .create-button {
    margin-top: 30px;
  }
}
.empty-icon {
  background: $empty-state-background;
  height: 140px;
  width: 140px;
  border-radius: 50%;
  margin: 32px auto;
}

.hover-tooltip {
  background-color: $primary;
  color: #fff;
  position: absolute;
  top: -80px;
  left: -75px;
  min-width: 180px;
  min-height: 50px;
  border: 1px solid #000;
  border-radius: 2px;
  font-size: 10px;
  line-height: 12px;
  font-style: normal;
  z-index: 5;

  span {
    color: $primary-grey2;
  }
  p {
    margin: 8px 0 0;
  }
  span,
  p {
    padding-left: 36px;
  }
  .address {
    display: inline-block;
    padding-bottom: 8px;
  }
  &::before {
    content: url('../assets/images/truck-white-back.png');
    position: absolute;
    display: inline-block;
    left: 8px;
    top: 8px;
  }
  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    left: 50%;
    transform: translateX(-50%);
    bottom: -4px;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: $primary transparent transparent transparent;
  }
  span.vehicle-status-text {
    margin: 0;
    padding: 0;
  }

  span.vehicle-range-charge-tooltip-text {
    margin: 0;
    padding: 0;
  }
}

.base-widget {
  border: 0.5px solid #c4c4c4;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.show-more-link {
  margin-top: 16px;
  display: flex;
  text-decoration: none;
  justify-content: center;
  cursor: pointer;
  color: $primary-bg;
  font-size: 12px;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}

.widget-title {
  color: #fff;
  margin-bottom: 24px;
  font-weight: 400;
  white-space: nowrap;
}

.widget-main-section {
  border: 0.5px solid $priority-low;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  &:hover {
    border: 1px solid $primary-bg;
  }
  &.charger-view:hover {
    border: 1px solid $request-completed;
  }
}

.xos-link {
  color: $primary-bg;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.xos-rhs-panel {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 0 96px rgba(0, 0, 0, 0.16);
}

.xos-sidenav-container {
  height: 100%;
  .mat-drawer-backdrop {
    position: fixed;
    left: 0;
    bottom: 0;
    top: 48px;
  }
}

.battery {
  border: 1px solid $priority-low;
  border-radius: 8px;
  height: 56px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 8px 8px 24px 0;
    border-color: transparent $priority-low transparent transparent;
    background: $priority-low;
    border-radius: 0 8px 8px 0;
    right: -8px;
    top: 12px;
  }
}

.profile-details-tabs {
  padding-bottom: 32px;
  .mat-tab-labels {
    justify-content: center;
    background: $primary-grey;
    border-radius: 100px;
    height: 32px;
    margin: 0 24px;
    .mat-tab-label {
      width: 50%;
      border: none;
      color: $primary-grey2;
      height: 32px;
      &.mat-tab-label-active {
        border: none;
        background-color: $primary;
        margin: 4px;
        color: #fff;
        border-radius: 100px;
        height: 24px;
        font-weight: normal;
      }
    }
  }
}

.charging-profile-modal {
  .modal-body {
    xos-schedule-widget ul li {
      margin-right: 17px;
    }
  }
  .modal-header .modal-title {
    font-size: 15px;
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-icon-wrapper {
  display: inline-block;
  margin-top: 2px;
  margin-right: 5px;
}

.info {
  display: flex;
  margin-bottom: 8px;
}

.info-text {
  color: $double-grey;
  font-size: 16px;
  white-space: nowrap;
}

.tooltip-wrapper {
  position: relative;
  &:hover {
    xos-tooltip {
      display: block;
    }
  }
}

.tooltip-title {
  font-weight: 400;
  margin-bottom: 2px;
  display: block;
  color: #fff;
  font-size: 10px;
}

.tooltip-items {
  list-style-type: none;
  font-size: 10px;
  font-weight: 400;
  color: $primary-grey2;
  li {
    margin-bottom: 2px;
  }
}

.switch-tabs {
  padding-bottom: 0;
  .mat-tab-label-container .mat-tab-labels {
    justify-content: center;
    background: $primary-grey;
    border-radius: 100px;
    height: 32px;
    margin: 0;
    font-weight: 400;
    .mat-tab-label {
      width: 50%;
      border: none;
      color: $primary-grey2;
      height: 32px;

      &.mat-tab-label-active {
        border: none;
        background-color: $primary;
        margin: 4px;
        color: #fff;
        border-radius: 100px;
        height: 24px;
        font-weight: normal;
      }
    }
  }
  .mat-tab-body-wrapper {
    margin-top: 12px;
  }
}

.chargers-preview,
.preventative-preview,
.history-preview,
.reports-preview,
.knowledge-preview {
  width: 352px;
  height: 200px;
}

.chargers-preview {
  background-image: url('../assets/images/feature-preview/chargers-preview.png');
}

.preventative-preview {
  background-image: url('../assets/images/feature-preview/preventative-preview.png');
}

.history-preview {
  background-image: url('../assets/images/feature-preview/history-preview.png');
}

.reports-preview {
  background-image: url('../assets/images/feature-preview/reports-preview.png');
}

.knowledge-preview {
  background-image: url('../assets/images/feature-preview/knowledge-preview.png');
}

.responsive-form {
  mat-error {
    display: none;
  }

  @include tablet-and-mobile {
    mat-error {
      display: block;
      ul {
        list-style-type: disc;
        margin-left: 12px;
      }
    }
    xos-error-tooltip {
      display: none;
    }
    mat-form-field .mat-input-element.ng-invalid.ng-dirty {
      border-color: $red;
    }
  }
}

.responsive-cards {
  background-color: $empty-state-background;
  margin: 20px 0;
  .mat-card {
    padding: 12px !important;
    border-radius: 2px !important;
  }
  .mat-card-content {
    display: flex;
    justify-content: space-between;
    div {
      font-weight: 600;
      color: $primary-grey2;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    span {
      font-weight: 400;
      color: $double-grey;
    }
    .actions {
      display: flex;
      margin-right: 10px;
    }
  }
}

.filter-toggle.mat-icon-button {
  background: $primary-grey;
  width: 32px;
  height: 32px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    width: 18px;
    height: 17px;
    margin-left: 1px;
  }
  &.hidden {
    background: $primary-bg;
    i {
      background: url('../assets/images/filter-white.svg');
      margin-left: 0;
    }
  }
  &.has-hidden-columns {
    border: 1px solid $primary-bg;
  }
}

table.xos-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px !important;

  tr {
    background: $primary-grey-opacity;
    border-radius: 2px;
    cursor: pointer;
  }

  tr.mat-header-row {
    background: transparent;

    th {
      color: $primary-grey2;
      font-weight: 600;
      &.checkbox-header {
        padding: 0;
        .xos-checkbox {
          border: 8px solid #fff;
          margin-left: 8px;
          &:hover {
            border: 8px solid $primary-grey-hover;
          }
        }
      }
      &.without-sort {
        padding-left: 18px;
      }
    }
  }

  th,
  td {
    font-size: 14px;
    border: none;
  }

  td {
    color: $double-grey;
    height: 56px;
    border: 1px solid $primary-grey-opacity;
    border-right: none;
    border-left: none;
    padding-left: 10px;
    &.mat-cell:first-of-type {
      padding-left: 0;
      border-left: 1px solid $primary-grey-opacity;
      border-radius: 2px 0 0 2px;
    }
    &.mat-cell:last-of-type {
      border-right: 1px solid $primary-grey-opacity;
      border-radius: 0 2px 2px 0;
    }
    &.flagged {
      padding-left: 0;
    }
    &.highlighted-text {
      color: $primary-bg;
      font-weight: 600;
      padding-left: 20px;
      &.first-column {
        padding-left: 42px;
      }
    }
  }
  .xos-checkbox {
    border: 8px solid $empty-state-background;
    color: $priority-low;
    margin-left: 5px;
    &:hover {
      color: $request-pending;
      background-color: $primary-grey-hover;
      border: 8px solid $primary-grey-hover;
      border-radius: 50%;
      &::ng-deep.mat-checkbox-inner-container {
        background-color: #fff;
      }
    }
  }
  .actions-button {
    width: 32px;
    height: 32px;
    line-height: 32px;
    &:hover,
    &[aria-expanded='true'] {
      background: $primary-grey-hover;
    }
  }

  .actions-wrapper {
    align-items: center;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        margin-right: 3px;
      }
    }
  }

  th.mat-header-cell:last-of-type,
  td.mat-cell:last-of-type,
  td.mat-footer-cell:last-of-type {
    padding-right: 10px;
  }

  .hovered {
    display: inline-flex;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 20px;
    &:hover {
      background: $primary-grey-hover;
    }
  }

  .handle {
    border-left: 2px solid $empty-state-background;
    height: 100%;
    display: flex;
    align-items: center;
    &.unread {
      border-color: $primary-bg;
    }
  }

  .hovered.circle {
    width: 32px;
    height: 32px;
  }

  .mat-row:hover {
    .mat-cell {
      border: 1px solid $priority-low;
      border-left: none;
      border-right: none;
      &:first-of-type {
        border-left: 1px solid $priority-low;
      }
      &:last-of-type {
        border-right: 1px solid $priority-low;
      }
    }
  }

  .mat-row.selected {
    .mat-cell {
      border: 1px solid #c77b41;
      border-left: none;
      border-right: none;
      &:first-of-type {
        border-left: 1px solid #c77b41;
      }
      &:last-of-type {
        border-right: 1px solid #c77b41;
      }
    }
  }

  .flagged {
    i {
      vertical-align: middle;
    }
  }

  .invisible {
    visibility: hidden;
  }

  .asset-type {
    padding-right: 20px;
    .icon-charger-grey {
      width: 19px;
      height: 19px;
    }
    .icon-vehicle-grey {
      width: 18px;
      height: 18px;
    }
    .xos-icon {
      vertical-align: bottom;
    }
  }
}

.actions-wrapper,
.paginator-wrapper {
  display: flex;
  justify-content: space-between;
}

.xos-input.nickname {
  .mat-input-element {
    background-color: #fff;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    &:active,
    &:focus {
      border: none;
      background-color: #fff;
    }
    &::placeholder {
      font-size: 16px;
    }
  }
}

.arrow-tabs {
  .mat-tab-header {
    background: #fff;
    z-index: 3;
    position: sticky;
    top: 62px;
  }

  .mat-tab-labels {
    background-color: #fff;
    border-bottom: 0.5px solid $priority-low;
    padding-left: 24px;
    @include mobile-screen {
      padding-left: 12px;
    }
  }

  .mat-tab-body-wrapper {
    margin-top: 0;
  }

  .mat-tab-label-content {
    font-weight: 400;
    font-size: 16px;
    color: $double-grey;
  }

  .mat-tab-label {
    padding: 0;
    margin-left: 6px;
    &:first-child {
      margin-left: 0;
    }
  }

  .mat-tab-disabled {
    opacity: 0.3;
  }

  .mat-tab-label-active .mat-tab-label-content {
    color: $primary;
    font-weight: 500;
  }

  .icon-arrow-right {
    margin-top: 16px;
    height: 10px;
    width: 12px;
  }
}

.charger-action.mat-flat-button {
  display: flex;
  justify-content: center;
  background-color: $request-completed;
  font-size: 14px;
  color: #fff;
  padding: 5px;
  width: 100%;
  border: 0.5px solid $priority-low, 0.5px solid $request-completed, 0.5px solid $request-completed;
  box-sizing: border-box;
  border-radius: 0 0 6px 6px;
  margin: 0 auto;
  font-weight: 400;
  &:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), $light-green;
  }
  &.details {
    margin: 0 24px 35px;
    width: calc(100% - 48px);
    border-radius: 2px;
    height: 32px;
    line-height: 20px;
    &.charging {
      background-color: #fff;
      color: $primary;
      border: 1px solid #000;
      &:hover {
        background-color: $primary;
        color: #fff;
      }
    }
  }
  &.mat-flat-button.mat-button-disabled {
    background-color: $request-completed;
    opacity: 0.3;
    color: #fff;
  }
  &.charging {
    background-color: $primary;
    border: 0.5px solid $priority-low, 0.5px solid $primary, 0.5px solid $primary;
  }
  &.preparing:disabled {
    background-color: $request-completed;
    border: 0.5px solid $priority-low, 0.5px solid $request-completed, 0.5px solid $request-completed;
    color: #fff;
    opacity: 0.3;
  }
  &.finishing:disabled {
    background-color: $primary;
    border: 0.5px solid $priority-low, 0.5px solid $primary, 0.5px solid $primary;
    color: #fff;
    opacity: 0.3;
  }
}

.widget-item-header {
  min-height: 251px;
  box-sizing: border-box;
  padding: 14px 12px;
  background: linear-gradient(238.02deg, $primary 5.99%, $light-black 85.42%);
  border: 0.5px solid $double-grey;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.view-icon-wrapper {
  background: $primary-grey;
  border-radius: 2px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 4px;
  left: 262px;
  cursor: pointer;
  &:hover {
    background: $primary-grey-hover;
  }
}

.active {
  .user-icon-wrapper {
    &:not(.avatar-wrapper) {
      background-color: $primary-bg;
    }
  }
  .default-icon {
    font-weight: 600;
  }
}

.vehicle-mat-tab-group {
  .mat-tab-header {
    border-bottom: 0.5px solid $priority-low;
    background: #fff;
    z-index: 3;
    position: sticky;
    top: 68px;
  }
  .mat-tab-body-wrapper {
    margin-top: 0;
  }
  .mat-tab-labels {
    padding-left: 24px;
    .mat-tab-label {
      font-size: 16px;
      color: $double-grey;
      font-weight: 400;
      &:hover,
      &.mat-tab-label-active {
        color: $primary;
        font-weight: 500;
        border-bottom-width: 1px;
      }
    }
  }
}

#hubspot-chat-container {
  z-index: 10;
  width: 400px;
  height: 500px;
  position: fixed;
  bottom: 0;
  left: 0;
  display: none;
  i {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

#hubspot-conversations-inline-iframe {
  width: 400px;
  height: 500px;

  nav {
    background-color: rgb(7, 7, 7) !important;
  }
}

.waiting-cursor {
  cursor: wait;
}
.waiting-cursor table.xos-table tr {
  cursor: wait;
}
